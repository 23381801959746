<template>
    <v-container fluid class="py-0" :class="miniVariant == true ? 'pl-9' : ''">
        <LoadingMe
            :isLoading="showLoading"
            style="padding: 0"
            :fullPage="false"
            :myLoading="true" />
        <v-row style=" margin: 0; width: 100%; display: block; background: #fff; padding: 10px;" id="invoiceContentS">
            <h1 style="width: 100%;text-align: center;font-weight: bold;background-color: #000;color: #fff!important;font-size: 18px;padding: 5px 0">{{ $t('session_report') }}</h1>
            <v-col sm="12" cols="12" class="text-lett pb-0"  style="padding-bottom: 15px;border-bottom: 2px solid #000;">
                <v-simple-table>
                    <template v-slot:default>
                        <tbody class="tb-pong">
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('store')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    {{ activeStore.name}}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('register')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    {{ counterName }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('session_num')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    {{ session.number }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('start_date')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    {{ dateTimeFormat(session.startDate) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('end_date')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    {{ dateTimeFormat(session.endDate) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('close_by')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    {{ session.closeBy}}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('start_amount')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    USD {{ session.amtUSD}}; KHR {{ session.amtKHR }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0">
                                    {{$t('print_date')}}
                                </td>
                                <td class="text-center pl-0">:</td>
                                <td class="text-right px-0">
                                    {{ dateTimeFormat(new Date()) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col sm="12" cols="12" style="padding-bottom: 15px;border-bottom: 2px solid #000;">
                <h1 style="width: 100%;text-align: center;font-weight: bold;background-color: #000;color: #fff!important;font-size: 18px;padding: 5px 0">{{ $t('summary') }}</h1>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody class="tb-pong">
                            <tr>
                                <td class="text-left px-0 pt-2 tb_border_b">
                                    {{$t('total')}}
                                </td>
                                <td class="text-right px-0 pt-2 tb_border_b">
                                    {{ numberFormat(mainSubTotal) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('discount')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(session.totalDis) }}
                                </td>
                            </tr>
                            <tr style="background: #ddd!important;">
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('total_after_dis')}}
                                </td>
                                <td class="text-right px-0 tb_border_b" style="font-size: 18px; font-weight: bold;text-align: right;">
                                    {{ numberFormat(mainSubTotal - session.totalDis) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('other_charge')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(otherCharge) }}
                                </td>
                            </tr>
                            <tr >
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('bank_charge')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    -{{ numberFormat(bankCharge) }}
                                </td>
                            </tr>
                            <tr >
                                <td class="text-left px-0 tb_border_b">
                                    Return/Refund
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    -{{ numberFormat(RRAmount) }}
                                </td>
                            </tr>
                            <tr style="background: #eee!important;">
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('sub_total')}}
                                </td>
                                <td class="text-right px-0 tb_border_b" style="font-size: 18px; font-weight: bold;text-align: right;">
                                    {{ numberFormat(mainTotal) }}
                                </td>
                            </tr>
                            <tr v-if="totalVAT > 0">
                                <td class="text-left px-0 tb_border_b">
                                    VAT 10%
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(totalVAT) }}
                                </td>
                            </tr>
                            <tr v-if="totalIncTax > 0">
                                <td class="text-left px-0 tb_border_b">
                                    VAT Included
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(totalIncTax) }}
                                </td>
                            </tr>
                            
                            <tr v-if="totalPLTax > 0">
                                <td class="text-left px-0 tb_border_b">
                                    Public Lighting Tax
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(totalPLTax) }}
                                </td>
                            </tr>
                            <tr v-if="totalSPTax > 0">
                                <td class="text-left px-0 tb_border_b">
                                    Special Tax
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(totalSPTax) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('total_tax')}}
                                </td>
                                <td class="text-right px-0 tb_border_b" style="font-size: 18px; font-weight: bold;text-align: right;">
                                    {{ numberFormat(mainTax) }}
                                </td>
                            </tr>
                            <tr >
                                <td class="text-left px-0 pt-2 tb_border_b">
                                    {{$t('grand_total')}}
                                </td>
                                <td class="text-right px-0 pt-2 tb_border_b" style="font-size: 18px; font-weight: bold;text-align: right;">
                                    {{ numberFormat(totalAmount) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col sm="12" cols="12" style="padding-bottom: 15px;border-bottom: 2px solid #000;">
                <h1 style="width: 100%;text-align: center;font-weight: bold;background-color: #000;color: #fff!important;font-size: 18px;padding: 5px 0">{{ $t('nature') }}</h1>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody class="tb-pong">
                            <tr>
                                <td class="text-left px-0 pt-2 tb_border_b">
                                    {{$t('cash')}}
                                </td>
                                <td class="text-right px-0 pt-2 tb_border_b">
                                    {{ numberFormat(recCash) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('bank')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(recBank) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 pt-2 tb_border_b">
                                    {{$t('credit')}}
                                </td>
                                <td class="text-right px-0 pt-2 tb_border_b">
                                    {{ numberFormat(recCredit) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 pt-2 tb_border_b">
                                    Prepaid
                                </td>
                                <td class="text-right px-0 pt-2 tb_border_b">
                                    {{ numberFormat(recPoint) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col sm="12" cols="12" style="padding-bottom: 15px;border-bottom: 2px solid #000;">
                <h1 style="width: 100%;text-align: center;font-weight: bold;background-color: #000;color: #fff!important;font-size: 18px;padding: 5px 0">{{ $t('bank') }}</h1>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody class="tb-pong">
                            <tr v-for="b in session.banks" v-bind:key="b.id">
                                <td class="text-left px-0 tb_border_b">{{b.name}} ({{b.number}})</td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(b.total) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('bank_card')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(session.totalCard) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col sm="12" cols="12" style="padding-bottom: 15px;border-bottom: 2px solid #000;">
                <h1 style="width: 100%;text-align: center;font-weight: bold;background-color: #000;color: #fff!important;font-size: 18px;padding: 5px 0">{{ $t('other_payment_method') }}</h1>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody class="tb-pong">
                            <tr v-for="b in session.otherpays" v-bind:key="b.id">
                                <td class="text-left px-0 tb_border_b">{{b.name}}</td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(b.total) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col sm="12" cols="12" style="padding-bottom: 15px;border-bottom: 2px solid #000;">
                <h1 style="width: 100%;text-align: center;font-weight: bold;background-color: #000;color: #fff!important;font-size: 18px;padding: 5px 0">{{ $t('partner') }}</h1>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody class="tb-pong">
                            <tr v-for="b in session.partners" v-bind:key="b.id">
                                <td class="text-left px-0 tb_border_b">{{b.name}}-({{b.method}})</td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(b.total) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col sm="12" cols="12" style="padding-bottom: 15px;border-bottom: 2px solid #000;">
                <h1 style="width: 100%;text-align: center;font-weight: bold;background-color: #000;color: #fff!important;font-size: 18px;padding: 5px 0">{{ $t('other') }}</h1>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody class="tb-pong">
                            <tr>
                                <td class="text-left px-0 tb_border_b">{{$t('invoice')}} </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(session.totalInvoice) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">{{$t('store_credit')}} </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(session.totalSCredit) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('top_up_card')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(totalTopup) }}
                                </td>
                            </tr>
                            <tr >
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('credit_card')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(totalCreditCard) }}
                                </td>
                            </tr>
                            <tr >
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('pre_order_amount')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(preOrderAmt) }}
                                </td>
                            </tr>
                            <tr >
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('pre_order_qty')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(preOrderQty) }}
                                </td>
                            </tr>
                            <tr >
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('point_redeem')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(amtRedeem) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left px-0 tb_border_b">
                                    {{$t('modifier_amount')}}
                                </td>
                                <td class="text-right px-0 tb_border_b">
                                    {{ numberFormat(session.modiAmount) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <div v-if="!disTxnList">
                <v-row>
                    <!-- Txns -->
                    <v-col sm="12" cols="12" v-if="showTxn" class="pl-0">
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-center" style="border-right: 1px solid;">
                                            {{$t('transaction')}}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                        </v-simple-table>
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-left">
                                            {{$t('number')}}
                                        </th>
                                        <th class=" th_invoice text-left">
                                            {{$t('cashier')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('date')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('payment_method')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('amount')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody  class="">
                                    <tr v-for="t in txns" v-bind:key="t.pk">
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.txnNumber}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.pinUser.hasOwnProperty('name') ? t.pinUser.name : ''}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ dateTimeFormat(t.issuedDate) }} </td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.paidBy }}</td>
                                        <td style="border: 1px solid" class="th_invoice_r">{{ numberFormat(t.total) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <!-- Internal usae -->
                    <v-col sm="12" cols="12" class="pl-0" v-if="internaltxns.length > 0 && showInternal">
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-center" style="border-right: 1px solid;">
                                            {{$t('internal_usage')}}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                        </v-simple-table>
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-left">
                                            {{$t('number')}}
                                        </th>
                                        <th class=" th_invoice text-left">
                                            {{$t('cashier')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('date')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('discount')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('amount')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody  class="">
                                    <tr v-for="t in internaltxns" v-bind:key="t.pk">
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.txnNumber}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.pinUser.hasOwnProperty('name') ? t.pinUser.name : ''}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ dateTimeFormat(t.issuedDate) }} </td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ numberFormat(t.discount) }}</td>
                                        <td style="border: 1px solid" class="th_invoice_r">{{ numberFormat(t.total) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <!-- Void -->
                    <v-col sm="12" cols="12" class="pl-0" v-if="voidtxns.length > 0 && showVoid">
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-center" style="border-right: 1px solid;">
                                            {{$t('void')}}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                        </v-simple-table>
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-left">
                                            {{$t('number')}}
                                        </th>
                                        <th class=" th_invoice text-left">
                                            {{$t('cashier')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('date')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('discount')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('amount')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody  class="">
                                    <tr v-for="t in voidtxns" v-bind:key="t.pk">
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.txnNumber}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.pinUser.hasOwnProperty('name') ? t.pinUser.name : ''}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ dateTimeFormat(t.issuedDate) }} </td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ numberFormat(t.discount) }}</td>
                                        <td style="border: 1px solid" class="th_invoice_r">{{ numberFormat(t.voidAmount) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <!-- Credit Txns -->
                    <v-col v-if="creditTxns.length > 0 && showCredit" sm="12" cols="12" class="pl-0">
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-center" style="border-right: 1px solid;">
                                            {{$t('credit_transaction')}}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            </v-simple-table>
                                <v-simple-table class="" >
                                    <template v-slot:default>
                                    <thead class="th_invoice">
                                        <tr>
                                            <th class=" th_invoice text-left">
                                                {{$t('number')}}
                                            </th>
                                            <th class=" th_invoice text-left">
                                                {{$t('cashier')}}
                                            </th>
                                            <th class=" th_invoice_r text-left">
                                                {{$t('customer')}}
                                            </th>
                                            <th class=" th_invoice_r text-left">
                                                {{$t('date')}}
                                            </th>
                                            <th class=" th_invoice_r text-left">
                                                {{$t('amount')}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody  class="">
                                        <tr v-for="t in creditTxns" v-bind:key="t.pk">
                                            <td style="border: 1px solid" class="th_invoice"> {{ t.txnNumber}}</td>
                                            <td style="border: 1px solid" class="th_invoice"> {{ t.pinUser.hasOwnProperty('name') ? t.pinUser.name : ''}}</td>
                                            <td style="border: 1px solid" class="th_invoice"> {{ t.customer }}</td>
                                            <td style="border: 1px solid" class="th_invoice"> {{ dateTimeFormat(t.issuedDate) }} </td>
                                            <td style="border: 1px solid" class="th_invoice_r">{{ numberFormat(t.total) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                    </v-col>
                    <!-- Redeem -->
                    <v-col sm="12" cols="12" class="pl-0" v-if="redeemTxn.length > 0 && showRedeem">
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-center" style="border-right: 1px solid;">
                                            {{$t('redeem')}}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                        </v-simple-table>
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-left">
                                            {{$t('number')}}
                                        </th>
                                        <th class=" th_invoice text-left">
                                            {{$t('cashier')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('date')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('point')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody  class="">
                                    <tr v-for="t in redeemTxn" v-bind:key="t.pk">
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.txnNumber}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.pinUser.hasOwnProperty('name') ? t.pinUser.name : ''}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ dateTimeFormat(t.issuedDate) }} </td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ numberFormat(t.point) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <!-- Return Txns -->
                    <v-col v-if="returntxns.length > 0 && showReturn" sm="12" cols="12" class="pl-0">
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-center" style="border-right: 1px solid;">
                                            {{$t('return_transaction')}}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                        </v-simple-table>
                            <v-simple-table class="" >
                                <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-left">
                                            {{$t('number')}}
                                        </th>
                                        <th class=" th_invoice text-left">
                                            {{$t('cashier')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('date')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('return_amount')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody  class="">
                                    <tr v-for="t in returntxns" v-bind:key="t.pk">
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.txnNumber}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.pinUser.hasOwnProperty('name') ? t.pinUser.name : ''}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ dateTimeFormat(t.issuedDate) }} </td>
                                        <td style="border: 1px solid" class="th_invoice_r">-{{ numberFormat(t.returnAmt) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <!-- Top up Txns -->
                    <v-col v-if="topupTxns.length > 0 && showTopup" sm="12" cols="12" class="pl-0">
                        <v-simple-table class="" >
                            <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-center" style="border-right: 1px solid;">
                                            {{$t('top_up_transaction')}}
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                        </v-simple-table>
                            <v-simple-table class="" >
                                <template v-slot:default>
                                <thead class="th_invoice">
                                    <tr>
                                        <th class=" th_invoice text-left">
                                            {{$t('number')}}
                                        </th>
                                        <th class=" th_invoice text-left">
                                            {{$t('cashier')}}
                                        </th>
                                        <th class=" th_invoice text-left">
                                            {{$t('customer')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('date')}}
                                        </th>
                                        <th class=" th_invoice_r text-left">
                                            {{$t('amount')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody  class="">
                                    <tr v-for="t in topupTxns" v-bind:key="t.pk">
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.txnNumber}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.pinUser.hasOwnProperty('name') ? t.pinUser.name : ''}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.customer.hasOwnProperty('name') ? t.customer.name : ''}}</td>
                                        <td style="border: 1px solid" class="th_invoice"> {{ t.date }} </td>
                                        <td style="border: 1px solid" class="th_invoice_r">{{ numberFormat(t.amount) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
        </v-row>
        <v-row>
            <!-- Save & Cancel -->
            <v-col sm="12" cols="12">
                <div class="function_footer text-right">
                    <v-btn color="primary" class="float-right white--text text-capitalize"
                        @click="printHTML">
                        {{ $t('print') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { i18n } from "@/i18n";
import kendo from "@progress/kendo-ui"
const $ = kendo.jQuery
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
const telegramBotHandler = require("@/scripts/commerce/handler/telegramBotHandler")
const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''
export default {
    name: "PrintSession",
    props: {session: {}, stock: {}},
    data: () => ({
        totalSPTax: 0,
        totalPLTax: 0,
        preOrderAmt: 0,
        preOrderQty: 0,
        mainSubTotal: 0,
        mainDis: 0,
        bankCharge: 0,
        recCash: 0,
        recBank: 0,
        recCredit: 0,
        recPoint: 0,
        miniVariant: false,
        institute: {},
        showLoading: false,
        logoUrl: '',
        isloadTxn: false,
        registers: [],
        counterName: '',
        txns: [],
        decimal: 2,
        //credit
        isloadCreditTxn: false,
        creditTxns: [],
        //bank wallet
        activeStore: localStorage.getItem(instituteId + 'commStore') != null ? JSON.parse(localStorage.getItem(instituteId + 'commStore')) : {},
        bankWallets: [],
        setting: localStorage.getItem(instituteId + 'commRSetting') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRSetting')) : {},
        register: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {},
        internaltxns: [],
        voidtxns: [],
        transactions: [],
        returntxns: [],
        startMonthAmt: 0,
        startMonth: {},
        amtRedeem: 0,
        totalCreditCard: 0,
        redeemTxn: [],
        totalTopup: 0,
        topupTxns: [],
        totalVAT: 0,
        totalIncTax: 0,
        mainTax: 0,
        totalAmount: 0,
        mainTotal: 0,
        otherCharge: 0,
        RRAmount: 0,
        totalGuest: 0
    }),  
    methods: {
        numberFormat(value){
            return kendo.toString(parseFloat(value), `n${this.decimal}`);
        },
        loadRegister(){
            // window.console.log('load register')
            // commerceHandler.registerGets().then(res => {
            //     let d = res.data.data
            //     if(d.length > 0){
            //         this.registers = d
            //         let r = this.registers.filter((o)=>{
            //             return o.pk == this.session.register
            //         })
            //         if(r.length > 0){
            //             window.console.log(this.registers, this.session, r, 'register name')
            //             this.counterName = r[0].name
            //         }
            //     }
            // })
            this.counterName = this.register.name
        },
        dateTimeFormat(date){
            return kendo.toString(new Date(date), 'dd/MMM/yyyy HH:mm tt');
        },
        closeForm(){
            $('#closePrintSession').click()
        },
        printHTML(){
            let styleTemplate = `<style>
                * {
                    padding: 0;
                    margin: 0;
                    font-size: 14px;
                    font-family: 'Hanuman', Arial;
                    color: #000!important;
                }
                body {
                    background-color: #fff!important;
                    background: #fff!important;
                }
                #invoiceContent {
                    padding: 2.5%;
                    width: 95%;
                }
                .col-4{
                    float: left;
                    width: 33%;
                    min-height: 12em;
                }
                .col-8{
                    float: left;
                    width: 66%;
                    min-height: 12em;
                }
                h2.mt-4 {
                    margin-top: 16px;
                    font-size: 2em;
                    margin-bottom: 16px;
                }
                .col-6{
                    float: left;
                    width: 48%;
                    margin-top: 20px;
                }
                .itemlist {
                    min-height: 550px;
                }
                .col-6-right {
                    float: right;
                }
                .col-12{
                    float: left;
                    width: 100%;
                    margin-top: 20px;
                }
                th, td.tb_receipt{
                    border: 1px solid #000;
                    padding: 20px;
                    background-color: #eee!important;
                    background: #eee!important;
                    font-size: 1em;
                }
                td.tb_receipt{
                    font-size: .98em;
                    background-color: #fff!important;
                    background: #fff!important;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                .text-right {
                    text-align: right;
                }
                .covertotal {
                    padding-top: 20px;
                    border-top: 1px solid;
                }
                .covertotal td {
                    font-size: 1.2em;
                    padding: 3px;
                }
                .powerbanhji {
                    margin: 10px;
                    font-size: 1.5em;
                    text-align: center;
                }
            </style>`
            if(this.setting.receiptTemplate == '80mm'){
                styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 14px;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    img.logo {
                        width: 150px;
                        height: auto;
                    }
                    .vat{
                        width: 50%;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 2.5%;
                        width: 95%;
                    }
                    .col-4{
                        float: left;
                        width: 100%;
                        min-height: 160px;
                        text-align: center;
                        border: none!important;
                    }
                    .col-8{
                        float: left;
                        width: 100%;
                        padding-bottom: 15px;
                    }
                    h2.mt-4 {
                        font-size: 16px;
                        text-align: center;
                        background: #eee!important;
                        padding: 5px;
                    }
                    .col-6{
                        float: left;
                        width: 100%;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 5px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 11px;
                    }
                    td.tb_receipt{
                        font-size: 12px;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    td {
                        padding: 5px;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 20px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 12px;
                    }
                    .powerbanhji {
                        margin: 5px;
                        font-size: 16px;
                        text-align: center;
                    }
                </style>`
            }else if(this.setting.receiptTemplate == '58mm'){
                styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    img.logo {
                        width: 80px;
                        height: auto;
                    }
                    .vat{
                        width: 50%;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 1%;
                        width: 98%;
                    }
                    .col-4{
                        float: left;
                        width: 100%;
                        min-height: 160px;
                        text-align: center;
                        border: none!important;
                    }
                    .col-8{
                        float: left;
                        width: 100%;
                        padding-bottom: 15px;
                    }
                    h2.mt-4 {
                        font-size: 14px;
                        text-align: center;
                        background: #eee!important;
                        padding: 2px;
                    }
                    .col-6{
                        float: left;
                        width: 100%;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 2px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 10px;
                    }
                    td.tb_receipt{
                        font-size: 11px;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 5px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 10px;
                    }
                    .powerbanhji {
                        margin: 5px;
                        font-size: 14px;
                        text-align: center;
                    }
                </style>`
            }else if(this.setting.receiptTemplate == 'A5'){
                styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    img.logo {
                        width: 100px;
                        height: auto;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    .vat {
                        width: 40%;
                    }
                    #invoiceContent {
                        padding: 1%;
                        width: 98%;
                    }
                    .col-4{
                        float: left;
                        width: 25%;
                        min-height: 130px;
                    }
                    .col-8{
                        float: left;
                        width: 75%;
                        min-height: 115px;
                    }
                    h2.mt-4 {
                        font-size: 18px;
                        margin-bottom: 14px;
                    }
                    .col-6{
                        float: left;
                        width: 48%;
                        margin-top: 15px;
                    }
                    .itemlist {
                        min-height: 280px;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                        margin-top: 15px;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 10px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 13px;
                    }
                    td.tb_receipt{
                        font-size: 12px;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 10px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 12px;
                    }
                    .powerbanhji {
                        margin: 10px;
                        font-size: 16px;
                        text-align: center;
                    }
                    .th_invoice, .th_invoice_r {
                        padding: 10px;
                        border: 1px solid #000;
                    }
                    .tb_border_b {
                        padding: 10px 0;
                        border-bottom: 1px dotted #000;
                    }
                    .colright {
                        float: right!important;
                    }
                </style>`
            }
            window.console.log(this.setting.receiptTemplate, 'form')
            var  Win;
            Win = window.open('', '', 'width=1048, height=900');
            var printableContent = '',
                win = Win,
                doc = win.document.open();
            var htmlStart = 
            '<meta charset="utf-8" /><title></title><link href="https://raw.githubusercontent.com/choeun88/css/main/kendo.common.min.css" rel="stylesheet" type="text/css">' +
            '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
            '<link href="https://fonts.googleapis.com/css2?family=Hanuman:wght@100;400;900&display=swap" rel="stylesheet">' +
            '<link href="https://raw.githubusercontent.com/choeun88/css/main/printreceipt.css" rel="stylesheet">' + 
            styleTemplate +
            '</head><body><div class="row-fluid" >' +
            '<div id="invoiceContent" style="background: none!important;color: #000!important;" class="k-content document-body">';
            var htmlEnd = '</div></div></body></html>';
            printableContent = $('#invoiceContentS').html();
            // window.console.log(htmlStart + printableContent + htmlEnd, 'pp')
            doc.write(htmlStart + printableContent + htmlEnd);
            doc.close();
            setTimeout(function(){
                win.print();
                win.close();
            },1000);
        },
        loadSession(id) {
            this.showLoading = true 
            commerceHandler.sessionGet(id).then(res=>{
                this.showLoading = false
                this.session = res.data.data[0]
                this.loadRegister()
                if(this.session.status != 'close'){
                    this.$snotify.error(i18n.t('session_not_yet_close'));
                }
            })
        },
        // load txn
        txnCom(){
            this.showLoading = false
            this.isloadTxn = true
            let totalSale = 0,
            recCash = 0,
            recBank = 0,
            recCredit = 0,
            recPoint = 0,
            totalCash = 0,
            totalDis = 0,
            totalCredit = 0,
            totalInvoice = 0,
            totalSCredit = 0, 
            totalBankWallet = 0,
            totalCard = 0,
            totalFleet = 0,
            totalVAT = 0,
            totalIncTax = 0,
            khqr = 0,
            pointPay = 0,
            rramount = 0,
            rrqty = 0,
            voidamount = 0,
            voidqty = 0,
            bankCharge = 0,
            amtRedeem = 0,
            subTotal = 0,
            totalPLTax = 0,
            totalSPTax = 0,
            totalCreditCard = 0
            let preOrderQty = 0,
                preOrderAmt = 0
            this.preOrderQty = 0
            this.preOrderAmt = 0
            let totalOtherPay = 0
            let txns = []
            let transactions = []
            let internaltxns = []
            let voidtxns = []
            let redeemTxn = []
            let returntxns = []
            let creditTxns = []
            let topupTxns = []
            this.noCreditDebitSale = 0
            this.totalVMUSales = 0
            this.totalEcommerce = 0
            this.totalEmoney = 0
            this.totalToanChet = 0
            this.totalCDSale = 0
            this.totalPiPay = 0
            this.totalABAPay = 0
            this.totalKHQR = 0
            this.totalAliPay = 0
            this.totalCreditCard = 0
            this.totalWingPay = 0
            this.totalSaleOtherMean = 0
            this.noCustomer = 0
            this.voidAmt = 0
            this.voidQty = 0
            this.totalTopup = 0
            let totalTopup = 0
            let mainTotal = 0,
                mainTax = 0,
                mainSubTotal = 0,
                otherCharge = 0
            this.mainTotal = 0
            this.mainTax = 0
            this.mainSubTotal = 0
            this.otherCharge = 0
            this.totalPLTax = 0
            this.totalSPTax = 0
            let allcominv = true
            this.txnIds = []
            this.txnData = []
            this.totalIncTax = 0
            this.totalVAT = 0
            this.session.partners.forEach(p=>{
                p.total = 0
            })
            this.session.banks.forEach(p=>{
                p.total = 0
            })
            this.session.otherpays.forEach(p=>{
                p.total = 0
            })
            this.totalAmount = 0
            let totalAmount = 0
            this.creditTxns = []
            let totalGuest = 0
            if(this.txns.length > 0){
                this.showView = false
                this.txns.forEach(d=>{
                    if(d.type != 'serving' && d.type != 'internal'){
                        let g = d.guestCount || {}
                        let forMen = parseInt(g.forMen) || 0
                        let forWomen = parseInt(g.forWomen) || 0
                        let localMen = parseInt(g.localMen) || 0
                        let localWomen = parseInt(g.localWomen) || 0
                        totalGuest += (forMen + forWomen + localMen + localWomen)
                    }
                    let itotal = parseFloat(d.subTotal) || 0
                    let idis = parseFloat(d.discount) || 0
                    let icommis = parseFloat(d.comission) || 0
                    let iotherc = parseFloat(d.otherCharge) || 0
                    let ibankc = parseFloat(d.bankCharge) || 0
                    let itax = parseFloat(d.tax) || 0
                    let isubt = (((itotal - idis) - icommis) + iotherc) - ibankc
                    if(d.invoiceType == 'commercial'){
                        let ctax = isubt / 11
                        itax += ctax
                    }
                    
                    let igrandt = isubt + itax
                    let shift = d.shift || 1
                    let voidAmount = d.voidAmount || 0
                    let returnAmount = 0
                    if(d.type == 'preorder'){
                        preOrderAmt += igrandt
                        preOrderQty += 1
                    }else if(d.type == 'redeem'){
                        let amtR = parseFloat(d.redeemPoint) || 0
                        amtRedeem += amtR
                        redeemTxn.push({
                            pk: d.pk,
                            txnNumber: d.txnNumber,
                            number: d.txnNumber,
                            cashier: d.pinUser.name || '',
                            date: this.dateTimeFormat(d.issuedDate),
                            amount: this.numberFormat(igrandt),
                            pinUser: d.pinUser,
                            discount: d.discount,
                            issuedDate: d.issuedDate,
                            total: d.total,
                            point: d.redeemPoint || 0
                        })
                    }else if(d.type == 'return'){
                        returnAmount = parseFloat(d.total)
                        mainTotal -= parseFloat(d.total)
                        totalAmount -= parseFloat(d.total)
                        recCash -= parseFloat(d.total)
                        returntxns.push({
                            pk: d.pk,
                            txnNumber: d.txnNumber,
                            number: d.txnNumber,
                            cashier: d.pinUser.name || '',
                            date: this.dateTimeFormat(d.issuedDate),
                            amount: this.numberFormat(igrandt),
                            returnAmt: this.numberFormat(igrandt),
                            pinUser: d.pinUser,
                            discount: d.discount,
                            issuedDate: d.issuedDate,
                            total: d.total,
                        })
                    }else if(d.type == 'top_up'){
                        totalAmount += igrandt
                        mainSubTotal += itotal
                        mainTax += itax
                        mainTotal += isubt
                        otherCharge += iotherc
                        topupTxns.push({
                            pk: d.pk,
                            txnNumber: d.txnNumber,
                            number: d.txnNumber,
                            cashier: d.pinUser.name || '',
                            date: this.dateTimeFormat(d.issuedDate),
                            amount: this.numberFormat(igrandt),
                            customer: d.customer || {},
                            pinUser: d.pinUser,
                        })
                        totalTopup += igrandt
                        let nature = d.payNature || 'Cash'
                        if(nature == 'Bank'){
                            recBank += parseFloat(igrandt)
                        }else if(nature == 'Credit'){
                            recCredit += parseFloat(igrandt)
                        }else if(nature == 'Prepaid'){
                            recPoint += parseFloat(igrandt)
                        }else{
                            recCash += parseFloat(igrandt)
                        }
                        let bcharge = d.bankCharge || 0
                        bankCharge += parseFloat(bcharge)
                    }else if(d.type != 'serving' && d.type != 'internal' && voidAmount <= 0){
                        totalPLTax += parseFloat(d.plTax) || 0
                        totalSPTax += parseFloat(d.spTax) || 0
                        totalAmount += igrandt
                        mainSubTotal += itotal
                        mainTax += itax
                        mainTotal += isubt
                        subTotal += itotal
                        otherCharge += iotherc
                        let amtR = parseFloat(d.redeemPoint) || 0
                        amtRedeem += amtR
                        totalSale += parseFloat(d.total)
                        totalDis += parseFloat(d.discount)
                        this.noCustomer += 1
                        if(d.invoiceType == 'vat'){
                            allcominv = false
                            totalVAT += parseFloat(d.tax)
                        }else if(d.invoiceType == 'commercial'){
                            let tax = parseFloat(d.total)/11
                            totalIncTax += parseFloat(tax)
                        }else{
                            allcominv = false
                        }
                        if(Object.keys(d.partner).length > 0){
                            this.session.partners.forEach(p=>{
                                if(p.id == d.partner.id){
                                    p.total += igrandt
                                }
                            })
                            if(d.isCredit){
                                totalCredit += parseFloat(d.total)
                                this.noCreditDebitSale += 1
                                let cus = d.partner || {}
                                creditTxns.push({
                                    pk: d.pk,
                                    txnNumber: d.txnNumber,
                                    number: d.txnNumber,
                                    cashier: d.pinUser.name || '',
                                    customer: cus.name || '',
                                    date: this.dateTimeFormat(d.issuedDate),
                                    amount: this.numberFormat(igrandt),
                                    pinUser: d.pinUser,
                                    discount: d.discount,
                                    paidBy: d.paidBy,
                                    issuedDate: d.issuedDate,
                                    total: this.numberFormat(igrandt),
                                })
                            }else if(d.payNature == 'Bank'){
                                this.session.otherpays.forEach(p=>{
                                    let pay = d.paidBy.toString().toLowerCase()
                                    let name = p.name.toString().toLowerCase()
                                    if(pay.indexOf(name) != -1){
                                        // window.console.log(igrandt, p, 'in other')
                                        p.total += igrandt
                                        if(p.name == 'KHQR'){
                                            khqr += igrandt
                                        }
                                    }
                                })
                                if(d.paidBy.indexOf('(Bank)') != -1){
                                    totalCard += igrandt
                                }
                            }
                        }else{
                            if(d.paymentMethod == 'card'){
                                totalCard += igrandt
                            }else if(d.paymentMethod == 'bank'){
                                totalBankWallet += parseFloat(d.total)
                                this.noCreditDebitSale += 1
                                this.session.banks.forEach(p=>{
                                    if(p.name == d.bankPay.bankName && p.number == d.bankPay.accountNumber){
                                        p.total += igrandt
                                    }
                                })
                            }else if(d.paymentMethod == 'khqr'){
                                khqr += igrandt
                                this.noCreditDebitSale += 1
                            }else if(d.paymentMethod == 'point'){
                                pointPay += parseFloat(d.total)
                            }else if(d.paymentMethod == 'invoice'){
                                totalInvoice += parseFloat(d.total)
                            }else if(d.paymentMethod == 'storecredit'){
                                totalSCredit += parseFloat(d.total)
                                this.noCreditDebitSale += 1
                            }else if(d.paymentMethod == 'fleet'){
                                totalFleet += parseFloat(d.total)
                            }else if(d.paymentMethod == 'cash'){
                                if(d.paidBy == 'KHQR'){
                                    khqr += igrandt
                                    this.noCreditDebitSale += 1
                                }else if(this.session.otherpays.length > 0){
                                    if(d.paidBy == 'Cash'){
                                        let total = igrandt
                                        d.otherPays = d.otherPays || []
                                        if(d.otherPays.length > 0){
                                            d.otherPays.forEach(o=>{
                                                this.session.otherpays.forEach(p=>{
                                                    if(p.name == o.paymentMethod){
                                                        this.noCreditDebitSale += 1
                                                        p.total += parseFloat(o.amount)
                                                        totalOtherPay += parseFloat(o.amount) 
                                                        total -= parseFloat(o.amount)   
                                                    }
                                                })
                                            })
                                        }
                                        totalCash += total
                                    }else{
                                        this.session.otherpays.forEach(p=>{
                                            if(p.name == d.paidBy){
                                                this.noCreditDebitSale += 1
                                                p.total += igrandt
                                                totalOtherPay += igrandt  
                                            }
                                        })
                                    }
                                }else{
                                    let total = igrandt
                                    d.otherPays = d.otherPays || []
                                    if(d.otherPays.length > 0){
                                        d.otherPays.forEach(o=>{
                                            this.session.otherpays.forEach(p=>{
                                                if(p.name == o.paymentMethod){
                                                    this.noCreditDebitSale += 1
                                                    p.total += parseFloat(o.amount)
                                                    totalOtherPay += parseFloat(o.amount)
                                                    total -= parseFloat(o.amount)   
                                                }
                                            })
                                        })
                                    }
                                    if(!d.isCredit){
                                        totalCash += total
                                    }
                                }
                            }
                            if(d.payNature == 'Credit'){
                                let cus = d.customer || {}
                                creditTxns.push({
                                    pk: d.pk,
                                    txnNumber: d.txnNumber,
                                    number: d.txnNumber,
                                    cashier: d.pinUser.name || '',
                                    customer: cus.name || '',
                                    date: this.dateTimeFormat(d.issuedDate),
                                    amount: this.numberFormat(igrandt),
                                    pinUser: d.pinUser,
                                    discount: d.discount,
                                    paidBy: d.paidBy,
                                    issuedDate: d.issuedDate,
                                    total: this.numberFormat(igrandt),
                                })
                                totalCredit += parseFloat(igrandt)
                                this.noCreditDebitSale += 1
                                if(d.paidBy == 'Credit Card'){
                                    totalCreditCard += parseFloat(igrandt)
                                }
                            }
                        }
                        let f = this.setting.sessionForm
                        let allow = f.sendTtxn || 0
                        if(allow == true){
                            allow = 1
                        }
                        if(allow == 1){
                            txns.push({
                                pk: d.pk,
                                txnNumber: d.txnNumber,
                                number: d.txnNumber,
                                cashier: d.pinUser.name || '',
                                date: this.dateTimeFormat(d.issuedDate),
                                amount: this.numberFormat(igrandt),
                                pinUser: d.pinUser,
                                discount: d.discount,
                                issuedDate: d.issuedDate,
                                paidBy: d.paidBy,
                                total: this.numberFormat(igrandt),
                            })
                        }
                        transactions.push({
                            pk: d.pk,
                            txnNumber: d.txnNumber,
                            number: d.txnNumber,
                            cashier: d.pinUser.name || '',
                            date: this.dateTimeFormat(d.issuedDate),
                            amount: this.numberFormat(igrandt),
                            pinUser: d.pinUser,
                            discount: d.discount,
                            issuedDate: d.issuedDate,
                            paidBy: d.paidBy,
                            total: this.numberFormat(igrandt),
                        })
                        this.txnIds.push(d.pk)
                        this.txnData.push(d)
                        let nature = d.payNature || 'Cash'
                        if(nature == 'Bank'){
                            recBank += parseFloat(igrandt)
                        }else if(nature == 'Credit'){
                            recCredit += parseFloat(igrandt)
                        }else if(nature == 'Prepaid'){
                            recPoint += parseFloat(igrandt)
                        }else{
                            recCash += parseFloat(igrandt)
                        }
                        let bcharge = d.bankCharge || 0
                        bankCharge += parseFloat(bcharge)
                    }else if(d.type == 'internal' && shift == this.shiftNum){
                        internaltxns.push({
                            pk: d.pk,
                            txnNumber: d.txnNumber,
                            number: d.txnNumber,
                            cashier: d.pinUser.name || '',
                            date: this.dateTimeFormat(d.issuedDate),
                            amount: this.numberFormat(igrandt),
                            pinUser: d.pinUser,
                            discount: d.discount,
                            issuedDate: d.issuedDate,
                            total: this.numberFormat(igrandt),
                        })
                    }else if(voidAmount > 0 && shift == this.shiftNum){
                        voidamount += parseFloat(voidAmount)
                        voidqty += 1
                        voidtxns.push({
                            pk: d.pk,
                            txnNumber: d.txnNumber,
                            number: d.txnNumber,
                            cashier: d.pinUser.name || '',
                            date: this.dateTimeFormat(d.issuedDate),
                            amount: this.numberFormat(igrandt),
                            voidAmount: voidAmount,
                            pinUser: d.pinUser,
                            discount: d.discount,
                            issuedDate: d.issuedDate,
                            total: this.numberFormat(igrandt),
                        })
                    }
                    if(returnAmount > 0 && shift == this.shiftNum){
                        rramount += parseFloat(returnAmount)
                    }
                })
            }
            this.session.otherpays.forEach(p=>{
                if(p.name == 'KHQR'){
                    p.total = khqr
                }
            })
            if(allcominv){
                let totalwithr = totalSale - rramount
                totalIncTax = totalwithr / 11
            }
            //
            this.totalGuest = totalGuest
            this.session.guest = totalGuest
            this.totalSPTax = totalSPTax
            this.totalPLTax = totalPLTax
            this.preOrderAmt = preOrderAmt
            this.preOrderQty = preOrderQty
            this.otherCharge = otherCharge
            this.mainSubTotal = mainSubTotal
            this.mainTax = mainTax
            this.mainTotal = mainTotal
            this.totalAmount = totalAmount
            this.totalTopup = totalTopup
            this.subTotal = subTotal
            this.totalCreditCard = totalCreditCard
            this.bankCharge = bankCharge
            //
            this.recBank = recBank
            this.recCash = recCash
            this.recCredit = recCredit
            this.recPoint = recPoint
            //
            this.amtRedeem = amtRedeem
            this.totalCDSale = totalSale - totalCash
            //
            this.session.totalVAT = totalVAT
            this.totalVAT = totalVAT
            this.totalIncTax = totalIncTax
            this.session.totalSale = totalSale
            this.session.totalCash = totalCash - rramount
            this.session.totalDis = totalDis
            this.session.totalCredit = totalCredit
            this.session.totalInvoice = totalInvoice || 0
            this.session.totalSCredit = totalSCredit || 0
            this.session.totalBankWallet = totalBankWallet
            this.session.totalCard = totalCard
            this.session.totalOtherPay = totalOtherPay
            this.session.khqr = khqr
            this.session.pointPay = pointPay
            this.session.totalFleet = totalFleet
            this.session.txns = this.txns.length
            this.txns = transactions
            //
            this.internaltxns = internaltxns
            this.returntxns = returntxns
            this.voidtxns = voidtxns
            this.redeemTxn = redeemTxn
            this.creditTxns = creditTxns
            this.topupTxns = topupTxns
            //
            this.RRAmount = rramount
            this.voidAmt = voidamount
            this.voidQty = voidqty
            this.RRQTY = rrqty
            this.session.transactions = txns
            let set = this.setting.sessionForm || {}
            let rshift = set.openShift || false
            if(rshift ==  1){
                rshift = true
            }
            let startBy = this.session.openBy || ''
            if(this.session.shifts.length > 1){
                startBy = this.session.shifts[this.session.shifts.length -1].endBy || ''
            }
            if(rshift){
                this.session.closeBy = startBy
            }
            setTimeout(()=>{
                this.showView = true
            }, this.txns.length * 100)
            //
            if(creditTxns.length > 0){
                this.isloadCreditTxn = true 
            }
            let rePrint = this.session.rePrint || false
            if(!rePrint){
                this.sendSesTelegram(this.session)
            }
        },
        saveStartMonth(amt){
            let items = []
            let d = {
                ...this.startMonth,
                amount: parseFloat(this.startMonthAmt) + amt
            }
            items.push(d)
            this.saveEntity(items, '')
        },
        async saveEntity(items, type){
            if(items.length > 0){
                let d = {
                    data: items,
                    type: type
                }
                this.showLoading = true
                await commerceHandler.updateDb(d)
                this.showLoading = false
            }
        },
        bindTxn(d){
            d.forEach(dd=>{
                if(dd.type != 'serving'){
                    this.txns.push(dd)
                }
            })
        },
        loadTxn(key){
            // window.console.log('load txn')
            let d = {
                epsAtt: {
                    ':sesPk': this.session.pk + '/txns',
                    ':startDate': new Date(this.session.startDate).getTime().toString(),
                    ':endDate': new Date(this.session.endDate).getTime().toString(),
                },
                index: 'sesPk-searchDate-index',
                keyCondition: 'sesPk = :sesPk and searchDate between :startDate and :endDate',
                key: key
            }
            this.showLoading = true
            commerceHandler.reportGets(d).then(res => {
                window.console.log(res, 'res txn')
                let data = res.data.data
                if(data.Items.length > 0){
                    this.bindTxn(data.Items)
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        this.loadTxn(data.LastEvaluatedKey)
                    }else{
                        this.txnCom()
                    }
                }else{
                    this.txnCom()
                }
            })
        },
        // load credit txn
        creditTxnCom(){
            this.showLoading = false
            if(this.creditTxns.length > 0){
                this.isloadCreditTxn = true 
            }
        },
        bindCreditTxn(d){
            d.forEach(dd=>{
                this.creditTxns.push(dd)
            })
        },
        loadCreditTxn(key){
            window.console.log('load txn')
            let d = {
                epsAtt: {
                    ':sesPk': this.session.pk + '/txninv',
                    ':startDate': new Date(this.session.startDate).getTime().toString(),
                    ':endDate': new Date(this.session.endDate).getTime().toString(),
                },
                index: 'sesPk-searchDate-index',
                keyCondition: 'sesPk = :sesPk and searchDate between :startDate and :endDate',
                key: key
            }
            this.showLoading = true
            commerceHandler.reportGets(d).then(res => {
                window.console.log(res, 'res credit txn')
                let data = res.data.data
                if(data.Items.length > 0){
                    this.bindCreditTxn(data.Items)
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        this.loadCreditTxn(data.LastEvaluatedKey)
                    }else{
                        this.creditTxnCom()
                    }
                }else{
                    this.creditTxnCom()
                }
            })
        },
        // setting
        async checkSetting(){
            const f = this.setting.sessionForm
            window.console.log(this.setting, 'setting')
            this.isloadTxn = false
            this.txns = []
            this.startMonthAmt = 0
            this.startMonth = {}
            let startm = {
                store: this.activeStore.pk,
                month: instituteId + kendo.toString(new Date(), 'yyyy-MM'),
                type: 'startmonth'
            }
            await commerceHandler.generalGet(startm).then(res=>{
                window.console.log(res, 'res on start month')
                if(res.data.data.Items.length > 0){
                    this.startMonthAmt = res.data.data.Items[0].amount
                    this.startMonth = res.data.data.Items[0]
                }
            })
            this.loadTxn({})
            //show credit
            if(f.showCredit == 1 || f.showCredit == true){
                this.isloadCreditTxn = false
                this.creditTxns = []
                this.loadCreditTxn({})
            }
            //bank wallet
            if(f.bankW == 1 || f.bankW == true){
                await this.loadBankWallet()
            }
        },
        async loadBankWallet() {
            this.showLoading = true
            commerceHandler.settingGet(this.activeStore.pk).then(res => {
                this.showLoading = false
                if (res.data.statusCode === 200) {
                    const data = res.data.data
                    if (data.length > 0) {
                        this.bankWallets = data[0].bankWallet
                    }
                }
            })
        },
        loadSetting(){
            this.showLoading = true
            commerceHandler.settingGet(this.session.register).then(res => {
                this.showLoading = false
                if (res.data.statusCode === 200) {
                    const data = res.data.data
                    if (data.length > 0) {
                        this.data = data[0]
                        this.setting = this.data
                        this.checkSetting()
                    }
                }
            })
        },
        async initData() {
            this.loadRegister()
            this.checkSetting()
            window.console.log(this.stock, 'stock on session')
        },
        sendSesTelegram(data){
            if(this.setting.telegramGroup != ''){
                let d = {
                    //version
                    vers: 3,
                    groupId: this.setting.telegramGroup,
                    type: 'sesionreport',
                    //info
                    store: this.activeStore.name,
                    storePhone: this.activeStore.phone,
                    register: this.register.name,
                    sesNum: data.number,
                    startDate: new Date(data.startDate).getTime(),
                    endDate:  new Date(data.endDate).getTime(),
                    closeBy: data.closeBy,
                    //sumarry
                    total: this.numberFormat(this.mainSubTotal),
                    discount: this.numberFormat(this.session.totalDis),
                    totalADis: this.numberFormat(this.mainSubTotal - this.session.totalDis),
                    otherCharge: this.numberFormat(this.otherCharge),
                    bankCharge: this.numberFormat(this.bankCharge),
                    refund: this.numberFormat(this.RRAmount),
                    subTotal: this.numberFormat(this.mainTotal),
                    totalTax: this.numberFormat(this.mainTax),
                    grandTotal: this.numberFormat(this.totalAmount),
                    //nature
                    natureCash: this.recCash,
                    natureBank: this.recBank,
                    natureCredit: this.recCredit,
                    naturePrepaid: this.recPoint,
                    //bank
                    banks: data.banks,
                    bankCard: this.numberFormat(this.session.totalCard),
                    //other payments
                    otherpays: this.session.otherpays,
                    //partner
                    partners: data.partners,
                    //loyalty
                    allowLoyalty: this.setting.allowLoyalty,
                    topUpCounter: data.topUpCounter || 0,
                    topUpGCard: data.topUpGCard || 0,
                    saleGiftCard: data.saleGiftCard || 0,
                    saleVoucher: data.saleVoucher || 0,
                    saleLCard: data.saleLCard || 0,
                    saleLCardAmount: data.saleLCardAmount || 0,
                    //avg
                    avgSaleG: parseInt(data.guest) > 0 ? parseFloat(this.totalAmount) / parseInt(data.guest) : 0,
                    guest: parseInt(data.guest),
                    avgSaleT: parseInt(data.txns) > 0 ? parseFloat(this.totalAmount) / parseInt(data.txns) : 0,
                    //other
                    invoices: data.totalInvoice,
                    storeCredit: this.numberFormat(this.session.totalSCredit),
                    topup: this.numberFormat(this.totalTopup),
                    creditCard: this.numberFormat(this.totalCreditCard),
                    preOAmt: this.numberFormat(this.preOrderAmt),
                    preOQty: this.numberFormat(this.preOrderQty),
                    pointRedeem: this.numberFormat(this.amtRedeem),
                    modiAmt: this.numberFormat(this.session.modiAmount),
                    txns: data.txns,
                    //
                    startMonthAmt: this.startMonthAmt + parseFloat(this.totalAmount),
                }
                this.showLoading = true
                telegramBotHandler.posPost(d).then(()=>{
                    this.showLoading = false 
                })
            }
        },
    },
    components: {
        LoadingMe: () => import(`@/components/Loading`),
    },
    computed:{
        disTxnList(){
            let set = this.setting.sessionForm || {}
            let dis = set.disTxnList || false
            return dis
        },
        showTxn(){
            let set = this.setting.sessionForm || {}
            let dis = set.txnList || false
            return dis
        },
        showVoid(){
            let set = this.setting.sessionForm || {}
            let dis = set.voidList || false
            return dis
        },
        showCredit(){
            let set = this.setting.sessionForm || {}
            let dis = set.creitList || false
            return dis
        },
        showReturn(){
            let set = this.setting.sessionForm || {}
            let dis = set.returnList || false
            return dis
        },
        showInternal(){
            let set = this.setting.sessionForm || {}
            let dis = set.internalList || false
            return dis
        },
        showRedeem(){
            let set = this.setting.sessionForm || {}
            let dis = set.redeemList || false
            return dis
        },
        showPreorder(){
            let set = this.setting.sessionForm || {}
            let dis = set.preOrderList || false
            return dis
        },
        showTopup(){
            let set = this.setting.sessionForm || {}
            let dis = set.topUpList || false
            return dis
        },
    },
    created: async function () {
        let r = this.register
        this.institute = {
            vatTin: r.vat,
            companyAddress: r.address,
            companyPhone: r.phone
        }
        let hasLogo = false 
        if(r.hasOwnProperty('logo')){
            if(Object.keys(r.logo).length > 0){
                if(r.logo.hasOwnProperty('default')){
                    if(r.logo.default.thumb != ''){
                        hasLogo = true
                    }
                }
            }
        }
        if(hasLogo){
            this.logoUrl = 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + r.logo.default.thumb
        }
    },
    mounted: async function (){
        await this.initData()
    },
    watch: {
        'session': 'initData'
    }
};
</script>

<style scoped>
    .tb_border_b{
        border-bottom: 2px dotted !important;
    }
    .tb_receipt{
        border: 0.2px solid black;
        border-right: 0px;
    }
    .tb_receipt_r{
        border: 0.2px solid black;
    }
    .th_invoice{
        border: 0.2px solid black;
        border-right: 0px;
        color: black !important;
        font-weight: 700;
    }
    .th_invoice_r{
        border: 0.2px solid black;
        color: black !important;
        font-weight: 700;
    }
    #pin > .theme--light.v-input input {
        max-height: 32px;
        font-size: 2rem !important;
        text-align: center;
    }
    .v-image__image--cover {
        background-size: contain;
        margin-top: 5px;
    }
    .v-image__image {
        background-position: top center !important;
    }
    .b-cash:before {
        color: #ffffff;
    }   
    .b-loyalty:before {
        content: "\e91b";
        color: #ffffff;
    }
    .btn-right .v-btn__content i{
       font-size: 40px;
    }
    .btn-right .v-btn__content{
       display: block !important;
    }
    h6{
       color: #2a2b2b;
       font-size: 0.6rem !important; 
       font-family: "Niradei-bold", serif !important;
       padding-top: 5px;
    }
    
    .btn-right{
        background-color: #ffffff !important;
        border-bottom: 1px solid;
        border-color: #e5e5e6 !important;
        width: 100%;
        height: 12% !important;
        text-align: center;
        padding: 0 3px !important;
        border-radius: 0px !important;  
    }
    .v-tab {
        justify-content: left;
        font-size: 20px;
    }

    .v-tab--active {
        background-color: rgb(255, 255, 255);
    }

    .tab_setting .v-tab--active {
        font-weight: 700;
        color: #000;
    }

    .v-tab--active {
        background-color: #ffffff !important;
        border-bottom: 4px solid #92d050;
        border-left: none;
    }

    p {
        color: rgba(0, 0, 0, 0.87);
    }
    .theme--light.v-tabs-items {
        background-color: #FFFFFF;
        height: 550px !important;
        overflow: scroll !important;
    }
    
    .sale-container{
        max-width: 1870px !important;
    }
    .theme--light.v-tabs-items {
        background-color: #ffffff00 !important;
    }
    .card-item{
        background-color: #ffffff00;
        border: 0.2px solid #dcdcdc33;
        border-radius: 0;
    }
    .catagory-btn{
        width: 100%;
        background-color: #ffffff !important;
        border: 0.2px solid #e4e4e6;
    }
    .v-btn__content{
        font-family: "Niradei-Bold", serif !important;
        display: block !important;
    }
    .btn_h2{
        font-size: 22px;
    }
    .sidebar-left{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 12.666667%;
        flex: 0 0 12.666667%;
        max-width: 12.666667%;
    }
    .sidebar-left2{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38.666667%;
        flex: 0 0 38.666667%;
        max-width: 38.666667%;
    }
    .sidebar-left3{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7.000000%;
    flex: 0 0 7.000000%;
    max-width: 7.000000%;
    }
    .container h2 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .btn-sidebar{
        justify-content: space-between !important;
        background-color: #fff !important;
        font-family: "Niradei-bold", serif !important;
    }

    .b-payment:before {
        content: "\e91f";
        color: #ffffff;
    }
    .b-banking:before {
        content: "\e90e";
        color: #ffffff;
    }
    .btn-money {
        background-color: #969696 !important;
        color: white;
    }
    
    .calculator{
        border: 1px solid gainsboro;
    }
    .receipt-btn{
        min-width: 22% !important;
        height: 50px !important;
        padding: 10px !important;
        margin: 5px;
    }
    .apply-btn{
        min-width: 33% !important;
        height: 50px !important;
        padding: 5px !important;
        margin: 5px;
    }
    .calculator-btn{
       min-width: 18% !important;
        margin: 4px;
        padding: 0 10px !important;
    }
    .calculator-btn-1{
        min-width: 20% !important;
        margin: 4px;
        padding: 0 2px !important;
        font-size: 13px !important;
        letter-spacing: inherit;
        font-weight: bold;
    }
    .pay-btn{
        width: 100%;
        border: 1px solid #e0e0e0;
    }
    .v-text-field__detail .field-pos{
        display:none !important;
    }
    .btn-funtion{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
    }
    .function_content {
        padding: 0px;
        border-bottom: none !important;
        background-color: #f8f8f9;
    }
    .notification{
        background-color: #ed263a;
        width: auto;
        margin-left: 2px;
        color: #fff;

    }
    .v-btn.v-size--default {
        font-size: 1.2rem;
    }
    .b-search.b:before {
        color: #d7d3d3 !important;
    }
    /* pin */
    body {
      height: 95vh;
      background-color: #181c26 !important;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Open Sans;
    }

    body.wrong {
      -webkit-animation: bg-red 1s ease-in;
      animation: bg-red 1s ease-in;
    }

    body.correct {
      -webkit-animation: bg-green 1s ease-in;
      animation: bg-green 1s ease-in;
    }

    #inspiration {
      position: fixed;
      right: 1em;
      bottom: 1em;
    }

    #inspiration a {
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      color: white;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
    }

    #inspiration a:hover { color: #212121; }

    #inspiration p {
      margin: 0;
      padding-left: .4em;
      display: inline-block;
      color: rgba(255, 255, 255, 0.6);
    }
    #pin {
        background-color: #ffffff !important;
        width: 90%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* padding: 1em; */
        border-radius: .3em;
        /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); */
        margin: auto;
        color: rgb(155 27 46);;
        }

    .dots {
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 1em;
      padding-top: 3em;
    }

    .dot {
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.8em;
      width: 0.8em;
      height: 0.8em;
      -webkit-transform: scale3d(0.7, 0.7, 0.7);
      transform: scale3d(0.7, 0.7, 0.7);
    }

    .dot.active {
      -webkit-animation: growDot .5s ease;
      animation: growDot .5s ease;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .dot.wrong {
      -webkit-animation: wrong .9s ease;
      animation: wrong .9s ease;
    }

    .dot.correct {
      -webkit-animation: correct .9s ease;
      animation: correct .9s ease;
    }

    .cancelPin {
      width: 25%;
      margin-left: 10%;
      margin-top: 10%;
    }
    .letter_spacing{
        letter-spacing:initial;
        font-size: 12px !important;
    }

    #pin p { font-size: 1.2em; }

    .numbers {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      margin: 1em 0;
    }

    .number {
      position: relative;
      width: 2.5em;
      height: 2.5em;
      margin: 0.5em;
      border-radius: 2.5em;
      border: 2px solid rgb(154 27 46);
      text-align: center;
      line-height: 2.5em;
      font-weight: 400;
      font-size: 1.8em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      cursor: pointer;
    }

    .number:hover { color: rgba(243, 134, 134, 0.5); }

    .number:hover:before { border: 2px solid rgba(255, 255, 255, 0.5); }

    .number:before {
      content: "";
      position: absolute;
      left: -2px;
      width: 2.5em;
      height: 2.5em;
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 2.5em;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
    }
    .number.grow:before {
        -webkit-animation: grow .6s ease;
        animation: grow .6s ease;
        }
    @-webkit-keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @-webkit-keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @-webkit-keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @-webkit-keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @-webkit-keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @-webkit-keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    @keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    #pin >.v-input input {
        text-align: center !important;
        font-size: 35px !important;
    }
    .td-invioce td{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .tb-pong td{
        height: 30px !important;
    }
    .th-invoice th{
      border-top: thin solid rgba(0, 0, 0, 0.12) !important;  
    }
    .v-list-item-left {
        padding: 0 10px !important;
    }
    .theme--light.v-divider{
        border-color: rgb(0 0 0 / 0%);
    }


    /* Surface pro */
    @media only screen and (min-width : 1400px){
        .btn-funtion{
            font-size: 14px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 8px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 18px;
            height: 18px;
            width: 18px;
        }
        .calculator-btn{
            font-size: 14px;
        }
        
    }
   @media only screen and (min-device-height : 720px) and (max-device-width : 1280px) {
    
        /** Surface Pro styles here **/
        .btn-funtion{
            font-size: 12px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 5px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 16px;
            height: 10px;
            width: 10px;
        }
        .calculator-btn{
            font-size: 12px;
        }
    }
    .angle_action {
        right: 0;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .angle_action_small {
        right: 12px;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .b-order_type:before {
        content: "\e933";
        color: #ffffff;
        font-size: 22px;
    }
    .b-reward_s:before {
        content: "\e937";
        color: #ffffff;
        font-size: 22px;
    }
    .b-promotion:before {
        content: "\e936";
        color: #ffffff;
        font-size: 22px;
    }
    .b-count_guest:before {
        content: "\e935";
        color: #ffffff;
        font-size: 22px;
    }
    .b-note_s:before {
        content: "\e932";
        color: #ffffff;
        font-size: 22px;
    }
    .b-delivery_s:before {
        content: "\e931";
        color: #ffffff;
        font-size: 22px;
    }
    .b-parksale:before {
        content: "\e934";
        color: #ffffff;
        font-size: 22px;
    }
    .b-invoice_s:before {
        content: "\e930";
        color: #ffffff;
        font-size: 22px;
    }

</style>